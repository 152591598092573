<template>
  <div class="default-tabela pdv-tabela">
    <div class="tabela-titulo">
      <h1>{{ camposInput.titulo }}</h1>
      <div style="position: relative;">
        <DownloadButton
          area="relatorio_de_lojistas"
          :getData="downloadExcelAll"
          :title="'Relatório de Lojistas'"
        />
      </div>
    </div>

    <div class="tabela-filtros default-formulario">
      <div class="formulario-item">
        <label class="formulario-item-label">Razão Social:</label>
        <input type="text" v-model="form.razaoSocial" placeholder="Nome" />
      </div>
      <div class="formulario-item">
        <label class="formulario-item-label">CNPJ:</label>
        <input
          type="text"
          v-model="form.cnpj"
          v-mask="'99.999.999/9999-99'"
          placeholder="00.000.000/0000-00"
        />
      </div>
      <div class="formulario-item">
        <div class="container-input container-datepicker">
          <label class="formulario-item-label">Razao Social Distribuidor:</label>
          <input type="text" v-model="form.razaoSocialDistribuidor" placeholder="Razao Social Distribuidor" />  
        </div>
      </div>
      
      <div class="formulario-item">
        <label class="formulario-item-label">Estado:</label>
        <multiselect
          v-model="form.uf"
          :options="estados"
          :searchable="false"
          :allow-empty="false"
          placeholder="Selecione"
          select-label="Filtrar"
          deselect-label=""
          selected-label="Selecionado"
          @close="fecharSelect('status')"
        />
      </div>

      <div class="btns-formulario">
        <button class="btn btn-enviar" @click="filtrar">Filtrar</button>
        <button class="btn" @click="limparFiltros">Limpar</button>
      </div>
    </div>
    <!-- .tabela-filtro -->

    <transition name="fade-left" mode="out-in">
      <div v-if="semDados" class="tabela-sem-dados" key="Vazio">
        <p>Sem dados no momento</p>
      </div>
      <div v-else class="tabela-informacoes" key="Lista">
        <div class="tabela-linha tabela-linha-titulo">
          <div class="tabela-linha-nome"><p>Razão Social</p></div>
          <div class="tabela-linha-cpf"><p>CNPJ</p></div>
          <div class="tabela-linha-cadastro"><p>Razao Social Distribuidor</p></div>
          <div class="tabela-linha-uf"><p>UF</p></div>
          <!-- <div class="tabela-linha-btns"></div> -->
        </div>
        <div
          class="tabela-linha tabela-linha-informacoes"
          v-for="itemTabela in dadosTabela"
          :key="itemTabela.id"
        >
          <div class="tabela-linha-nome">
            <p>{{ itemTabela.razaSocial }}</p>
          </div>
          <div class="tabela-linha-cpf">
            <p>{{ itemTabela.cnpj | formataCNPJ }}</p>
          </div>
          <div class="tabela-linha-cadastro">
            <p>{{ itemTabela.razaoSocialDistribuidor }}</p>
          </div>
          <div class="tabela-linha-uf">
            <p>{{ itemTabela.uf }}</p>
          </div>

          <!-- <div class="tabela-linha-btns">
            <div
              class="tabela-linha-btns-editar"
              @click="verItem(itemTabela.id)"
            >
              <img
                src="~@/assets/images/icones/see.svg"
                alt="Detalhes"
                title="Detalhes"
              />
            </div>
          </div> -->
        </div>
      </div>
    </transition>
    <div class="tabela-paginacao paginacao" v-if="!semDados">
      <div>
        <p>Total</p>
      </div>
      <div>
        {{ paginacao.totalPaginas }}
      </div>
      <div
        class="paginacao-btn"
        v-if="paginacao.numeroPagina >= 2"
        @click="nudaPagina(1)"
      >
        <p>1</p>
      </div>
      <div>
        <div class="button" v-if="pageNumber > 1" @click="prevPage()">
          <img
            src="~@/assets/images/icones/next.svg"
            alt="<"
            title="Anterior"
            style=" transform: rotate(180deg);"
          />
        </div>
      </div>
      <div
        v-if="paginacao.numeroPagina > 1"
        @click="nudaPagina(paginacao.numeroPagina - 1)"
      >
        <p>{{ paginacao.numeroPagina - 1 }}</p>
      </div>
      <div class="pagina-atual" v-if="paginacao.numeroPagina >= 1">
        {{ paginacao.numeroPagina }}
      </div>
      <div
        v-if="
          paginacao.totalPaginas >= 3 &&
            paginacao.numeroPagina < paginacao.totalPaginas
        "
        @click="nudaPagina(paginacao.numeroPagina + 1)"
      >
        <p>{{ paginacao.numeroPagina + 1 }}</p>
      </div>
      <div>
        <div
          class="button"
          v-if="dadosTabela.length === 10"
          @click="nextPage()"
        >
          <img src="~@/assets/images/icones/next.svg" alt=">" title="Próximo" />
        </div>
      </div>
      <div
        class="paginacao-btn"
        v-if="paginacao.numeroPagina < paginacao.totalPaginas"
        @click="nudaPagina(paginacao.totalPaginas)"
      >
        <p>{{ paginacao.totalPaginas }}</p>
      </div>
    </div>
    <!-- <Paginacao :paginacao="paginacao" pagina="usuario"  v-if="!semDados"/> -->
    <!-- .tabela-paginacao -->
  </div>
</template>

<script>
import AwesomeMask from "awesome-mask";
import { usuarioListarTodos, gerarRelatorio, GetExcelAdminById } from "@/api/pdv";
import { _imagemMobile, _imagemDesktop } from "@/api/_caminhosApi";
import { numberToReal } from "@/utils/money";
import { filterData, filterDataHora, stringToDatetimeZone } from "@/utils/date";
import { formataCNPJ } from "@/utils/masks";
import DownloadButton from "@/components/download-button/DownloadButton.vue";
// import Datepicker from "vuejs-datepicker";
import Multiselect from "vue-multiselect";
import { ptBR } from "vuejs-datepicker/dist/locale";
import moment from "moment";
// import Paginacao from "@/components/paginacao/Paginacao.vue";

let filtrosBase = {
  razaoSocial: null,
  cnpj: null,
  razaoSocialDistribuidor: null
};
export default {
  directives: {
    mask: AwesomeMask
  },
  data() {
    return {
      ptBR: ptBR,
      planilhaParaDownload: [],
      tituloDownload: "",
      pageNumber: 1,
      semDados: true,
      form: filtrosBase,
      filtros: {},
      calendario: {
        ativacao: ""
      },
      calendarioTexto: {
        placeholder: "Selecione o período",
        fromText: "De",
        toText: "a",
        resetText: "Limpar",
        confirmText: "Confirmar",
        weekList: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
        monthList: [
          "Janeiro",
          "Fevereiro",
          "Março",
          "Abril",
          "Maio",
          "Junho",
          "Julho",
          "Agosto",
          "Setembro",
          "Outubro",
          "Novembro",
          "Dezembro"
        ]
      },
      estados: [
        "Acre",
        "Alagoas",
        "Amapá",
        "Amazonas",
        "Bahia",
        "Ceará",
        "Distrito Federal",
        "Espirito Santo",
        "Goiás",
        "Maranhão",
        "Mato Grosso do Sul",
        "Mato Grosso",
        "Minas Gerais",
        "Pará",
        "Paraíba",
        "Paraná",
        "Pernambuco",
        "Piauí",
        "Rio de Janeiro",
        "Rio Grande do Norte",
        "Rio Grande do Sul",
        "Rondônia",
        "Roraima",
        "Santa Catarina",
        "São Paulo",
        "Sergipe",
        "Tocantins",
      ]
    };
  },
  components: {
    DownloadButton,
    Multiselect
    // Datepicker
    // Paginacao
  },
  computed: {
    camposInput() {
      return this.$store.state.pdv.componenteAtivado;
    },
    dadosTabela() {
      console.log(this.$store.state.pdv.tabela)
      return this.$store.state.pdv.tabela;
    },
    paginacao() {
      return this.$store.state.pdv.paginacao;
    }
  },
  methods: {
    _imagemMobile,
    _imagemDesktop,
    numberToReal,
    gerarRelatorio,
    montarDownload(data) {
      this.tituloDownload = ["Relatório de PDVs"];
      if (data && Object.keys(data).length) {
        return (this.planilhaParaDownload = data.map((v) => ({
          CNPJ: this.formataCNPJ(v.cnpj),
          "Razão Social": v.razaoSocial,
          "Endereco": v.endereco,
          "Número": v.numero,
          "Complemento": v.complemento,
          "Bairro": v.bairro,
          "CEP": v.cep,
          "Cidade": v.cidade,
          "Estado": v.uf,
          "Quantidade de balconistas": v.qtdeBalconistas,
          "Aceite Política de Privacidade": v.aceitePoliticaPrivacidade,
          "Aceite Regulamento": v.aceiteRegulamento,
          "Data de Ativação do Cadastro": v.dataConfirmacaoCadastro ? moment(v.dataConfirmacaoCadastro).format("DD/MM/YYYY") : "",
          "Pré-Cadastrado": v.preCadastrado,          
        })));
      }
    },
    getDD(number) {
      return number.substring(0, 2);
    },
    removeDD(number) {
      if (number) return (number = number.replace(/^.{2}/g, ""));
    },
    prevPage() {
      this.pageNumber = this.pageNumber - 1;
    },
    nextPage() {
      this.pageNumber = this.pageNumber + 1;
    },
    nudaPagina(id) {
      this.pageNumber = id;
    },
    novoEnvio() {
      this.$store.commit("pdv/SET_TIPO_FORMULARIO", "Novo");
      this.$store.commit("pdv/SET_PDV_TELA", "Formulario");
    },
    verificaComponente() {
      return usuarioListarTodos(this.pageNumber, 10, this.filtros);
    },
    formataCNPJ(cnpj) {
      cnpj = cnpj.replace(/[^\d]/g, "");
      return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");

    },
    editarItem(id) {
      this.$store.commit("pdv/SET_TIPO_FORMULARIO", "Editar");
      this.$store.commit("pdv/SET_PDV_TELA", "Formulario");
      this.$store.commit("pdv/SET_ID_FORMULARIO_EDITAR", id);
    },
    verItem(id) {
      this.$store.commit("pdv/SET_TIPO_FORMULARIO", "Detalhes");
      this.$store.commit("pdv/SET_PDV_TELA", "Detalhes");
      this.$store.commit("pdv/SET_ID_FORMULARIO_EDITAR", id);
    },
    filtrar() {
      this.filtros = this.form;
      this.verificaComponente();
    },
    limparFiltros() {
      this.form = {
        nome: null,
        cpf: null,
        email: null,
        dataCadastroInicial: null,
        dataCadastroFinal: null
      };
      this.filtros = {};
      this.resetCalendario();
      this.verificaComponente();
    },
    atualizaCalendario(data) {
      this.form.dataConfirmacaoCadastro = stringToDatetimeZone(data.start);
    },
    resetCalendario() {
      this.form.dataConfirmacaoCadastro = "";
    },
    getData() {
      return this.gerarRelatorio().then(resp => {
        return this.montarDownload(resp.data);
      });
    },
    downloadExcelAll() {
      GetExcelAdminById().then(
        (res) => {
          const fileURL = window.URL.createObjectURL(new Blob([res.data],{
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          }));
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "RelatorioLojistas.xlsx");
          document.body.appendChild(fileLink);
          fileLink.click();
          this.$store.commit("interacoes/SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_ALERTA", {
            show: true,
            tipo: "erro",
            mensagem: error.response.data,
          });
        }
      );
    },
  },
  filters: {
    filterData,
    filterDataHora,
    formataCNPJ
  },
  mounted() {
    this.pageNumber = 1;
    this.verificaComponente();
    this.montarDownload();
  },
  watch: {
    camposInput() {
      this.verificaComponente();
    },
    dadosTabela() {
      this.semDados = this.dadosTabela.length <= 0;
    },
    pageNumber() {
      this.verificaComponente();
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.tabela-linha-nome,
.tabela-linha-cadastro {
  width: 35% !important;
  min-width: none !important;
  max-width: none !important;
}
.tabela-linha-cpf,
.tabela-linha-uf {
  width: 15% !important;
  min-width: none !important;
  max-width: none !important;
}
</style>
